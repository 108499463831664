


























import {
  Component, Vue,
} from 'vue-property-decorator';

import { Action } from 'vuex-class';

@Component({
  name: 'login',
})
export default class Login extends Vue {
  @Action('login') login!: (login: { username: string, password: string }) => void;

  private username: string = '';
  private password: string = '';

  private hasFailed: boolean = false;

  private isLoading: boolean = false;

  async attemptLogin() {
    this.isLoading = true;
    await this.login({
      username: this.username,
      password: this.password,
    });

    if (this.$store.state.user.loggedIn) {
      if (this.$route.query.redirect) {
        const { redirect } = this.$route.query;
        this.$router.push(Array.isArray(redirect) ? redirect[0] : redirect);
      } else {
        this.$router.push({ name: 'dashboard' });
      }
    } else {
      this.isLoading = false;
      this.hasFailed = true;
    }
  }
}
