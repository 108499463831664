

























import {
  Component, Vue,
} from 'vue-property-decorator';
import { Action } from 'vuex-class';
import {
  email,
} from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

const validations = {
  email: { email },
};

@Component({
  name: 'reset-password',
  validations,
  mixins: [validationMixin],
})
export default class ResetPassword extends Vue {
  @Action('resetPassword') resetPassword!: (email: string) => Promise<void>;
  private email: string = '';

  private hasFailed: boolean = false;
  private hasSent: boolean = false;
  private isLoading: boolean = false;

  async attemptPasswordReset() {
    this.hasSent = false;
    this.hasFailed = false;
    this.isLoading = true;
    try {
      await this.resetPassword(this.email);
      this.hasSent = true;
    } catch (e) {
      this.hasFailed = true;
    } finally {
      this.isLoading = false;
    }
  }
}
